import React from "react";
import { Helmet } from "react-helmet";
import { globalHistory as history } from "@reach/router";
import "./all.sass";
import useSiteMetadata from "./SiteMetadata";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";

const TemplateWrapper = ({children}) => {
  const { siteUrl, title, description, image, imgAlt } = useSiteMetadata();
  const { location } = history;

  let currentPath = '';
  let currentOrigin = siteUrl;
  try {
    if (!!location.href) {
      currentPath = location.href;
    }
    if (!!location.origin) {
      currentOrigin = location.origin;
    }
  } catch (e) {}
  return (
    <div>
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta name="description" content={description} />
        <link rel="canonical" href={currentPath} />
        <meta name="theme-color" content="#fff" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Schoolhouse by the Sea" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={`${currentOrigin}${image}`} />
        <meta property="og:url" content={currentPath} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={`${currentOrigin}${image}`} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:image:alt" content={imgAlt} />
      </Helmet>
      <div role="main">
        <div id="page-container">
          <Navbar />
          <div id="site-content">
            <div>
              <div>{children}</div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default TemplateWrapper;
