import React from "react";
import { Link } from "gatsby";
import logo from "../assets/images/logo.svg";

const Navbar = class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      navBarActiveClass: "",
    };
  }

  toggleHamburger = () => {
    // toggle the active boolean in the state
    this.setState(
      {
        active: !this.state.active,
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        this.state.active
          ? this.setState({
              navBarActiveClass: "is-active",
            })
          : this.setState({
              navBarActiveClass: "",
            });
      }
    );
  };

  render() {
    return (
      <nav
        className="navbar is-transparent"
        role="navigation"
        aria-label="main navigation"
      >
        <div className="navbar-brand">
          <Link
            to="/"
            className="navbar-item"
            title="Logo"
            style={{ display: "flex", alignItems: "center" }}
          >
            {/*A static width needs to be defined in px in order to work properly in IE11*/}
            <img
              src={logo}
              alt="SchoolhouseByTheSea"
              style={{ width: "260px", height: "auto", maxHeight: "1.5rem" }}
            />
          </Link>
          {/* Hamburger menu */}
          <div
            className={`navbar-burger burger ${this.state.navBarActiveClass}`}
            data-target="navMenu"
            role="presentation"
            onClick={() => this.toggleHamburger()}
            onKeyDown={(event) => {
              if (event.keycode === 13) this.toggleHamburger();
            }}
          >
            <span />
            <span />
            <span />
          </div>
        </div>
        <div
          id="navMenu"
          className={`navbar-menu ${this.state.navBarActiveClass}`}
        >
          <div className="navbar-start has-text-centered">
            <div className="navbar-item has-dropdown is-hoverable">
              <Link className="navbar-link" to="/students">
                Students
              </Link>
              <div className="navbar-dropdown is-boxed">
                <Link className="navbar-item" to="/students/arts-and-crafts">
                  Arts and Crafts
                </Link>
                <Link className="navbar-item" to="/students/fingerplays">
                  Fingerplays
                </Link>
                <Link className="navbar-item" to="/students/games">
                  Games
                </Link>
                <Link className="navbar-item" to="/students/math-games">
                  Math Games
                </Link>
                <Link className="navbar-item" to="/students/monthly-challenges">
                  Monthly Challenges
                </Link>
                <Link className="navbar-item" to="/students/movement">
                  Movement
                </Link>
                <Link className="navbar-item" to="/students/music">
                  Music
                </Link>
                <Link className="navbar-item" to="/students/poems-and-verses">
                  Poems and Verses
                </Link>
                <Link className="navbar-item" to="/students/read-alouds">
                  Read Alouds
                </Link>
                <Link className="navbar-item" to="/students/recipes">
                  Recipes
                </Link>
                <Link className="navbar-item" to="/students/science">
                  Science
                </Link>
                <Link className="navbar-item" to="/students/spelling-games">
                  Spelling Games
                </Link>
                <Link className="navbar-item" to="/students/stories">
                  Stories
                </Link>
                {/*<hr className="navbar-divider" />*/}
                <div className="navbar-item has-text-weight-bold">
                  More Resources Coming Soon
                </div>

                {/*<Link className="navbar-item" to="/students/social-emotional-learning">*/}
                <div className="navbar-item">
                  <em>Social Emotional Learning</em>
                </div>
                {/*</Link>*/}
              </div>
            </div>
            <div className="navbar-item has-dropdown is-hoverable">
              <Link className="navbar-link" to="/educators">
                Families and Educators
              </Link>
              <div className="navbar-dropdown is-boxed">
                <Link className="navbar-item" to="/educators/chalk-drawing">
                  Chalk Drawing
                </Link>
                <Link className="navbar-item" to="/educators/family-enrichment">
                  Family Enrichment
                </Link>
                <Link
                  className="navbar-item"
                  to="/educators/planning-resources"
                >
                  Planning Resources
                </Link>
                <Link className="navbar-item" to="/educators/recommendations">
                  Recommendations
                </Link>
                <Link
                  className="navbar-item"
                  to="/educators/student-materials-guide"
                >
                  Student Materials Guide
                </Link>
                <div className="navbar-item has-text-weight-bold">
                  More Resources Coming Soon
                </div>
                {/*<Link className="navbar-item" to="/educators/inspiration">*/}
                <div className="navbar-item">
                  <em>Inspiration</em>
                </div>
                {/*</Link>*/}
                {/*<Link className="navbar-item" to="/educators/reflections">*/}
                <div className="navbar-item">
                  <em>Reflections</em>
                </div>
                {/*</Link>*/}
                {/*<Link className="navbar-item" to="/educators/social-emotional-learning">*/}
                <div className="navbar-item">
                  <em>Social Emotional Learning</em>
                </div>
                {/*</Link>*/}
                {/*<Link className="navbar-item" to="/educators/tips-and-tricks">*/}
                <div className="navbar-item">
                  <em>Tips and Tricks</em>
                </div>
                {/*</Link>*/}
              </div>
            </div>
            {/*TODO: Complete Services Page*/}
            {/*<Link className="navbar-item" to="/services">*/}
            {/*  Services*/}
            {/*</Link>*/}
            <Link className="navbar-item" to="/blog">
              Blog
            </Link>
            <Link className="navbar-item" to="/about">
              About
            </Link>
            <Link className="navbar-item" to="/newsletter">
              Newsletter
            </Link>
            <Link className="navbar-item" to="/support-us">
              Support Us
            </Link>
          </div>
        </div>
      </nav>
    );
  }
};

export default Navbar;
