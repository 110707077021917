import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookSquare,
  // faTwitter,
  faInstagram,
  faPatreon,
  faPaypal,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { Link } from "gatsby";

const Footer = class extends React.Component {
  render() {
    var dateObj = new Date();
    var currentYear = dateObj.getFullYear();

    return (
      <footer className="footer has-background-white">
        <div className="content has-text-centered has-background-primary-dark has-text-white-ter">
          <div className="container has-background-primary-dark has-text-white-ter">
            <div style={{ maxWidth: "100vw" }} className="columns">
              <div className="column is-4">
                <section className="menu">
                  <ul className="menu-list">
                    <li>
                      <Link to="/" className="has-text-centered navbar-item">
                        Home
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="has-text-centered navbar-item"
                        to="/students"
                      >
                        Students
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="has-text-centered navbar-item"
                        to="/educators"
                      >
                        Families and Educators
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="has-text-centered navbar-item"
                        to="/blog"
                      >
                        Blog
                      </Link>
                    </li>
                    {/*<li>*/}
                    {/*  <Link className="navbar-item" to="/services">*/}
                    {/*    Services*/}
                    {/*  </Link>*/}
                    {/*</li>*/}
                    {/*<li>*/}
                    {/*  <a*/}
                    {/*    className="navbar-item"*/}
                    {/*    href="/admin/"*/}
                    {/*    target="_blank"*/}
                    {/*    rel="noopener noreferrer"*/}
                    {/*  >*/}
                    {/*    Admin*/}
                    {/*  </a>*/}
                    {/*</li>*/}
                  </ul>
                </section>
              </div>
              <div className="column is-4">
                <section>
                  <ul className="menu-list">
                    <li>
                      <Link
                        className="has-text-centered navbar-item"
                        to="/about"
                      >
                        About
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="has-text-centered navbar-item"
                        to="/newsletter"
                      >
                        Newsletter
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="has-text-centered navbar-item"
                        to="/support-us"
                      >
                        Support Us
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="has-text-centered navbar-item"
                        to="/contact"
                      >
                        Contact Us
                      </Link>
                    </li>
                  </ul>
                </section>
              </div>
              <div className="column is-4">
                <section className="d-flex justify-content-center align-items-center px-2 social">
                  <a
                    href="https://www.facebook.com/schoolhousebythesea"
                    className="has-text-white-ter px-2"
                    aria-label="facebook"
                  >
                    <FontAwesomeIcon
                      icon={faFacebookSquare}
                      size="2x"
                      aria-hidden="true"
                    />
                  </a>
                  <a
                    title="instagram"
                    href="https://www.instagram.com/schoolhouse_by_the_sea/"
                    className="has-text-white-ter px-2"
                    aria-label="instagram"
                  >
                    <FontAwesomeIcon
                      icon={faInstagram}
                      size="2x"
                      aria-hidden="true"
                    />
                  </a>
                  <a
                    title="patreon"
                    href="https://www.patreon.com/schoolhousebythesea"
                    className="has-text-white-ter px-2"
                    aria-label="patreon"
                  >
                    <FontAwesomeIcon
                      icon={faPatreon}
                      size="2x"
                      aria-hidden="true"
                    />
                  </a>
                  {/*<a*/}
                  {/*  title="twitter"*/}
                  {/*  href="https://twitter.com/sylviabythesea"*/}
                  {/*  className="has-text-white-ter px-2"*/}
                  {/*  aria-label="twitter"*/}
                  {/*>*/}
                  {/*  <FontAwesomeIcon icon={faTwitter} size="2x" aria-hidden="true"  />*/}
                  {/*</a>*/}
                  <a
                    title="youtube"
                    href="https://www.youtube.com/channel/UCEbKChTIHSjzJfkXhwauO-A/"
                    className="has-text-white-ter px-2"
                    aria-label="youtube"
                  >
                    <FontAwesomeIcon
                      icon={faYoutube}
                      size="2x"
                      aria-hidden="true"
                    />
                  </a>
                </section>
                <section>
                  <a
                    title="paypal"
                    href="https://paypal.me/metztechco"
                    className="has-text-white-ter px-2"
                    aria-label="paypal"
                  >
                    Donate via PayPal&nbsp;
                    <FontAwesomeIcon icon={faPaypal} aria-hidden="true" />
                  </a>
                </section>
              </div>
            </div>
          </div>
          <div className="container has-text-centered has-background-primary-dark has-text-white-ter">
            <div
              className="is-size-6"
              style={{ maxWidth: "100vw", padding: "1.5rem 0" }}
            >
              <em>
                Copyright &copy; {currentYear}{" "}
                <a className="light-link" href="https://metztech.co">
                  Metz Tech Co
                </a>{" "}
                - All Rights Reserved
              </em>
            </div>
          </div>
        </div>
      </footer>
    );
  }
};

export default Footer;
